<template>
    <enterprise-apply></enterprise-apply>

</template>

<script>

    import EnterpriseApply from "@/components/EnterpriseApply";
    export default {
        name: "enterEnterpriseApply",
        components: {
            EnterpriseApply
        },
        data() {
            return {}
        },
        mounted() {},
        methods:{}
    }
</script>

